import styled from "styled-components"
import { themeGet } from "styled-system"

export const ContactWrapper = styled.div`
position: relative;
padding: 90px 75px 0 75px;
@media (max-width: 990px) {
  padding: 80px 45px 30px 45px;
}
@media (max-width: 575px) {
  padding: 60px 25px 0 25px;
}
`
export const ContactDetails = styled.div`
  width: 870px;
  max-width: 100%;
  margin: 0 auto;

  p {
    font-size: ${themeGet("fontSizes.4", "16")}px;
    margin-bottom: 2em;
  }
`

export const ContactPageTitle = styled.h1`
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
  color: ${themeGet("colors.textColor", "#292929")};
  line-height: 1.53;

  @media (max-width: 990px) {
    font-size: 26px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
  }

  &:after {
    content: "";
    height: 8px;
    width: 120px;
    display: block;
    margin: 18px 0;
    background: ${themeGet("colors.mainTheme")};
  }
`

export const ContactFromWrapper = styled.div`
  position: relative;
  .button {
    margin-top: 60px;
    @media (max-width: 575px) {
      margin-top: 40px;
    }
  }
`

export const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .field-wrapper {
    flex-grow: 1;
    max-width: calc(50% - 30px);
    margin-bottom: 30px;
    @media (max-width: 575px) {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }
`

export const ContactPageSuccess = styled.div`
  display: none;
  background: #e5ffe5;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 40px;

  &.show {
    display: block;
  }
`
export const ContactPageError = styled.div`
  display: none;
  background: #ff6666;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 40px;

  &.show {
    display: block;
  }
`