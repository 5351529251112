import * as React from "react"
import { Formik, FormikActions, FormikProps, Form } from "formik"
import * as Yup from "yup"
import Input from "../../components/Input/Input"
import Button from "../../components/Button/Button"
import {
  ContactWrapper,
  ContactDetails,
  ContactPageTitle,
  ContactFromWrapper,
  ContactPageSuccess,
  ContactPageError,
  InputGroup,
} from "./style"

interface MyFormValues {
  firstName: string
  lastName: string
  telephone: string
  email: string
  message: string
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
let ShowError = false
let ShowSuccess = false

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  telephone: Yup.string()
  .matches(phoneRegExp, 'Phone number is not valid')
  .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  message: Yup.string().required("Required"),
})

const encode = (data : any ) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Contact: React.SFC<{}> = () => {
  return (
    <Formik
      initialValues={{ firstName: "", lastName: "", telephone: "", email: "", message: "" }}
      onSubmit={(
        values: MyFormValues,
        actions: FormikActions<MyFormValues>
      ) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...values })
        })
        .then(() => {
          ShowSuccess = true
          actions.resetForm()
        })
        .catch(() => {
          ShowError = true
        })
        .finally(() => actions.setSubmitting(false))
      }}
      validationSchema={SignupSchema}
      render={({
        handleChange,
        values,
        errors,
        handleBlur,
        touched,
        isSubmitting,
      }: FormikProps<MyFormValues>) => (
        <>
          <Form 
          name='contact'
          data-netlify={true}>
            <ContactWrapper>
              <ContactDetails>
              <ContactPageTitle>
                <b>Schedule a session</b>
              </ContactPageTitle>
              <p>
                Please feel free to leave me a message below and I will get in touch with you about arranging an appointment.
                </p>
                <br/>
              <ContactPageSuccess className={ShowSuccess ? 'show' : ''}>
                Thanks for your message.
              </ContactPageSuccess>
              <ContactPageError className={ShowError ? 'show' : ''}>
                Sorry. Something went wrong there. Try again or and if issue persist please try again later.
              </ContactPageError>
              <ContactFromWrapper>
                <InputGroup>
                  <Input
                    type="text"
                    name="firstName"
                    value={`${values.firstName}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="First name"
                    notification={`${
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }`}
                  />
                  <Input
                    type="text"
                    name="lastName"
                    value={`${values.lastName}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Last name"
                    notification={`${
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : ""
                    }`}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    type="text"
                    name="telephone"
                    value={`${values.telephone}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Phone number"
                    notification={`${
                      errors.telephone && touched.telephone
                        ? errors.telephone
                        : ""
                    }`}
                  />
                  <Input
                    type="email"
                    name="email"
                    value={`${values.email}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Email"
                    notification={`${
                      errors.email && touched.email ? errors.email : ""
                    }`}
                  />
                </InputGroup>
                <Input
                  type="textarea"
                  name="message"
                  value={`${values.message}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Message"
                  notification={
                    errors.message && touched.message ? errors.message : ""
                  }
                />
                <Button
                  title="Submit"
                  type="submit"
                  isLoading={isSubmitting ? true : false}
                  loader="Submitting.."
                />
              </ContactFromWrapper>
              </ContactDetails>
            </ContactWrapper>
          </Form>
        </>
      )}
    />
  )
}

export default Contact
